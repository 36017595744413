import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="YieldHub Finance" />
    <h1 style={{ marginBottom: "1.5rem" }}>
      Yield
      <span style={{
        padding: "9px 6px",
        marginLeft: "6px",
        backgroundColor: "black",
        borderRadius: "6px",
        color: "white"
      }}>hub</span></h1>
    <h2 style={{ marginBottom: "2rem" }}>
      Where all your gains come true
    </h2>
  </Layout>
)

export default IndexPage
