/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { StaticImage } from "gatsby-plugin-image"

// import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {

  return (
    <>
      <div
        style={{
          height: '100vh',
          width: '100vw',
          display: 'grid',
          justifyContent: 'center',
          alignContent: 'center',
          textAlign: 'center'
        }}
      >
        <main>{children}</main>
        <footer
          style={{

            display: 'flex',
            justifyContent: 'center',
            gap: '2rem',
            marginBottom: "2rem"
          }}
        >
          <a href="https://yieldhubfinance.gitbook.io/yieldhub-docs/" 
            target="_blank"
            rel="noreferrer" 
            title="docs"
          >
            <StaticImage
              src="../images/docs.svg"
              width={25}
              height={25}
              alt="Documents"
            />
          </a>
          <a 
            href="https://discord.gg/gxYaW2C8cs" 
            target="_blank"
            rel="noreferrer" 
            title="discord"
          >
            <StaticImage
              src="../images/discord.svg"
              width={25}
              height={25}
              alt="Discord"
            />
          </a>
          <a 
            href="https://github.com/yieldhubfinance" 
            target="_blank"
            rel="noreferrer"
            title="github"
          >
            <StaticImage
              src="../images/github.svg"
              width={25}
              height={25}
              alt="Github"
            />
          </a>
          <a href="https://twitter.com/yieldhubfinance" 
            target="_blank"
            rel="noreferrer"
            title="twitter"
          >
            <StaticImage
              src="../images/twitter.svg"
              width={25}
              height={25}
              alt="Twitter"
            />
          </a>

        </footer>

        <div>
          <a type="button" 
            href="https://app.yieldhub.finance"
            target="_blank"
            rel="noreferrer"
          >Enter App</a>
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
